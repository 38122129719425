import React, { useEffect, useState } from 'react'
import { collection, getDocs } from "firebase/firestore/lite";
import { dataBase } from '../../config/firebase';
import bgShopLeftSectionImg from '../../assets/shop-category-section.jpg';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default function SportsAndOutdoor() {

    const [products, setProducts] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(dataBase, 'products'));
                const productsData = querySnapshot.docs
                    .map(doc => doc.data())
                    .filter(prod => prod.category === 'sports-and-outdoor'); // Add the filter condition
                setProducts(productsData);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
            // try {
            //     const querySnapshot = await getDocs(collection(dataBase, 'products'));
            //     const productsData = querySnapshot.docs.map(doc => doc.data());
            //     setProducts(productsData);
            // } catch (error) {
            //     console.error('Error fetching products:', error);
            // }
        };

        fetchData();

        return () => {
        };

    }, []);


    return (
        <>
            <div className="container mb-5" style={{ marginTop: 150 }}>
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to='/' className='outline-0 text-decoration-none text-secondary'>Home</Link></li>
                        <li class="breadcrumb-item" aria-current="page"><Link to='/shop' className='outline-0 text-decoration-none text-secondary'>Shop</Link></li>
                        <li class="breadcrumb-item active" aria-current="page"><Link to='/shop' className='outline-0 text-decoration-none text-dark'>Sports & Outdoor</Link></li>
                    </ol>
                </nav>
                <br />
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3 p-4 shop-left-section rounded-2" style={{ height: 430, backgroundColor: "#edf2f9", backgroundImage: `url(${bgShopLeftSectionImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                        <p className='mb-0'><Link className='text-decoration-none text-dark outline-0' to='/beauty-and-personal-care'>Beauty & Personal Care</Link></p>
                        <p className='mb-0'><Link className='text-decoration-none text-dark outline-0' to='/home-and-kitchen'>Home & Kitchen</Link></p>
                        <p className='mb-0'><Link className='text-decoration-none text-dark outline-0' to='/sports-and-outdoor'>Sports & Outdoor</Link></p>
                        <p><Link className='text-decoration-none text-dark outline-0' to='/office-products'>Office Products</Link></p>
                        <Link to='/shop' className='text-decoration-none text-dark outline-0 d-flex align-items-center'>
                            <p className='mb-0 me-2'>Shop Now</p>
                            <FontAwesomeIcon fontSize={12} icon={faGreaterThan} />
                        </Link>
                    </div>
                    <div className="col-12 col-md-6 col-lg-9">
                        <div className="row">
                            {products
                                && (
                                    products.map((prod, i) => (
                                        <div className="col-10 col-md-6 col-lg-4 m-auto mb-3" key={i}>
                                            <div className="card" style={{ width: "100%" }}>
                                                <img src={prod.featuredImage} style={{ width: "100%", height: 250 }}
                                                    className="card-img-top" alt="..." />

                                                <div className="card-body">
                                                    <p style={{ fontSize: 14 }} className="mb-4 fw-bold">{prod.productTitle}</p>
                                                    <p className="card-title">{prod.description.substring(0, 27)}...</p>
                                                    <div className="d-flex">
                                                        <del className="text-secondary actual-price">{prod && prod.price}$</del>
                                                        <p className="ms-3 discounted-price">{prod && prod.discountedPrice}$</p>
                                                    </div>
                                                    <button className="btn btn-outline-dark cart-btn rounded-0 py-1 px-2 me-2"
                                                        style={{ fontSize: "small" }}>
                                                        Add To Cart
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))

                                )
                            }
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
