import React, { useEffect, useState } from 'react'
import { collection, getDocs } from "firebase/firestore/lite";
import { dataBase } from '../config/firebase';
import shopMain1 from '../assets/shop-main-1.png';
import shopMain2 from '../assets/shop-main-2.png';
import shopMain3 from '../assets/shop-main-3.png';
import bgShopLeftSectionImg from '../assets/shop-category-section.jpg';
import { faGreaterThan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default function Shop() {

    const [products, setProducts] = useState([])
    const [cart, setCart] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(dataBase, 'products'));
                const productsData = querySnapshot.docs.map(doc => doc.data());
                setProducts(productsData);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchData();

        return () => {
        };

    }, []);

    useEffect(() => {
        const updateCartFromLocalStorage = () => {
            const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
            setCart(existingCart);
        };

        updateCartFromLocalStorage();

        const handleCartChange = () => {
            updateCartFromLocalStorage();
        };

        window.addEventListener('cartChange', handleCartChange);

        return () => {
            window.removeEventListener('cartChange', handleCartChange);
        };
    }, []);

    const isProductInCart = (productSlug) => {
        return cart.some(item => item.slug === productSlug);
    };

    const addToCart = (product) => {
        const existingCart = [...cart];

        if (!isProductInCart(product.slug)) {
            existingCart.push(product);
            setCart(existingCart);
            localStorage.setItem('cart', JSON.stringify(existingCart));
            window.dispatchEvent(new Event('cartChange'));
        } else {
            alert('Product is already in the cart!');
        }
    };

    return (
        <>
            <div id="carouselExample" class="carousel slide">
                <div class="carousel-inner" style={{ height: "100vh" }}>
                    <div class="carousel-item active">
                        <div className="row px-5 g-0">
                            <div className="col-12 col-md-6 col-lg-8 m-auto">
                                <div className="d-flex align-items-center">
                                    <div className="border-bottom border-dark border-2 me-2" style={{ width: 52 }}></div>
                                    <p className='mb-0' style={{ fontSize: 13, fontWeight: 600 }}>NEW ARRIVALS</p>
                                </div>
                                <p style={{ fontSize: 54 }}>NIGHT SPRING <br /><span className='fw-bold'>DRESSES</span></p>
                                <p className='border-bottom border-2' style={{ width: 100 }}>Shop Now</p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <img src={shopMain1} class="d-block" width={"100%"} alt="..." />
                            </div>
                        </div>
                    </div>
                    <div class="carousel-item">
                        <div className="row px-5">
                            <div className="col-0 col-md-6 col-lg-8 m-auto">
                                <div className="d-flex align-items-center">
                                    <div className="border-bottom border-dark border-2 me-2" style={{ width: 52 }}></div>
                                    <p className='mb-0' style={{ fontSize: 13, fontWeight: 600 }}>NEW ARRIVALS</p>
                                </div>
                                <p style={{ fontSize: 54 }}>PREMIUM BRAND <br /><span className='fw-bold'>CLOTHING</span></p>
                                <p className='border-bottom border-2' style={{ width: 100 }}>Shop Now</p>
                            </div>
                            <div className="col-12 col-md-6 col-lg-4">
                                <img src={shopMain2} class="d-block" width={"100%"} alt="..." />
                            </div>
                        </div>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>

            <br />
            <br />

            <div className="container mb-5">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><Link to='/' className='outline-0 text-decoration-none text-secondary'>Home</Link></li>
                        <li class="breadcrumb-item active" aria-current="page"><Link to='/shop' className='outline-0 text-decoration-none text-dark'>Shop</Link></li>
                    </ol>
                </nav>
                <br />
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-3 p-4 shop-left-section rounded-2" style={{ height: 430, backgroundColor: "#edf2f9", backgroundImage: `url(${bgShopLeftSectionImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                        <p className='mb-0'><Link className='text-decoration-none text-dark outline-0' to='/beauty-and-personal-care'>Beauty & Personal Care</Link></p>
                        <p className='mb-0'><Link className='text-decoration-none text-dark outline-0' to='/home-and-kitchen'>Home & Kitchen</Link></p>
                        <p className='mb-0'><Link className='text-decoration-none text-dark outline-0' to='/sports-and-outdoor'>Sports & Outdoor</Link></p>
                        <p><Link className='text-decoration-none text-dark outline-0' to='/office-products'>Office Products</Link></p>
                        <Link to='/shop' className='text-decoration-none text-dark outline-0 d-flex align-items-center'>
                            <p className='mb-0 me-2'>Shop Now</p>
                            <FontAwesomeIcon fontSize={12} icon={faGreaterThan} />
                        </Link>
                    </div>
                    <div className="col-12 col-md-6 col-lg-9">
                        <div className="row">
                            {products
                                && (
                                    products.map((prod, i) => (
                                        <div className="col-10 col-md-6 col-lg-4 m-auto mb-3" key={i}>
                                            <div className="card" style={{ width: "100%" }}>
                                                <img src={prod.featuredImage} style={{ width: "100%", height: 250 }}
                                                    className="card-img-top" alt="..." />

                                                <div className="card-body">
                                                    <p style={{ fontSize: 14 }} className="mb-4 fw-bold">{prod.productTitle}</p>
                                                    <p className="card-title">{prod.description.substring(0, 27)}...</p>
                                                    <div className="d-flex">
                                                        <del className="text-secondary actual-price">{prod && prod.price}$</del>
                                                        <p className="ms-3 discounted-price">{prod && prod.discountedPrice}$</p>
                                                    </div>
                                                    {isProductInCart(prod.slug) ? (
                                                        <Link className="btn btn-outline-dark cart-btn rounded-0 py-1 px-2 me-2" to='/cart' style={{ fontSize: "small" }}>
                                                            View Cart
                                                        </Link>
                                                    ) : (
                                                        <button className="btn btn-outline-dark cart-btn rounded-0 py-1 px-2 me-2" style={{ fontSize: "small" }} onClick={() => addToCart(prod)}>
                                                            Add To Cart
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))

                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
