import React, { useEffect, useState } from 'react'
import firstMain from '../assets/hero/hero-1.jpg'
import secondMain from '../assets/hero/hero-2.jpg'
import secondSection1 from '../assets/second-section.jpg'
import secondSection2 from '../assets/second-section-2.jpg'
import brandLogo1 from '../assets/logos/1.png'
import brandLogo2 from '../assets/logos/2.png'
import brandLogo3 from '../assets/logos/3.png'
import brandLogo4 from '../assets/logos/4.png'
import brandLogo5 from '../assets/logos/5.png'
import brandLogo6 from '../assets/logos/6.png'
import ceoSignature from '../assets/signature.png'
import previous from '../assets/previous.webp'
import next from '../assets/next.webp'
import { collection, getDocs } from "firebase/firestore/lite";
import { dataBase } from '../config/firebase';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom'

export default function Home() {
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const querySnapshot = await getDocs(collection(dataBase, 'products'));
                const productsData = querySnapshot.docs.map(doc => doc.data());
                setProducts(productsData);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchData();

        return () => {
        };

    }, []);

    useEffect(() => {
        const updateCartFromLocalStorage = () => {
            const existingCart = JSON.parse(localStorage.getItem('cart')) || [];
            setCart(existingCart);
        };

        updateCartFromLocalStorage();

        const handleCartChange = () => {
            updateCartFromLocalStorage();
        };

        window.addEventListener('cartChange', handleCartChange);

        return () => {
            window.removeEventListener('cartChange', handleCartChange);
        };
    }, []);

    const isProductInCart = (productSlug) => {
        return cart.some(item => item.slug === productSlug);
    };

    const addToCart = (product) => {
        const existingCart = [...cart];

        if (!isProductInCart(product.slug)) {
            existingCart.push(product);
            setCart(existingCart);
            localStorage.setItem('cart', JSON.stringify(existingCart));
            window.dispatchEvent(new Event('cartChange'));
        } else {
            alert('Product is already in the cart!');
        }
    };

    return (
        <>
            <main>
                <div id="carouselExampleDark" className="carousel carousel-dark slide">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active"
                            aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                    </div>
                    <div className="carousel-inner mt-5">
                        <div className="carousel-item active">
                            <img src={firstMain} className="d-block m-auto" style={{ width: "100%" }} alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <p className="mb-0">Winter 2024 Collection</p>
                                <h2>Valentine Paul Essential Collection</h2>
                                <p>Some representative placeholder content for the first slide.</p>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={secondMain} className="d-block m-auto" style={{ width: "100%" }} alt="..." />
                            <div className="carousel-caption d-none d-md-block">
                                <p className="mb-0">Premium Collection</p>
                                <h2>Making Someone Pretty Is An Art</h2>
                                <p>Some representative placeholder content for the second slide.</p>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark"
                        data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark"
                        data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>

                <div className="container py-5">
                    <div className="row">
                        {products
                            && (
                                products.slice(0, 4).map((prod, i) => (
                                    <div className="col-10 col-md-6 col-lg-3 m-auto mb-3" key={i}>
                                        <div className="card" style={{ width: "100%" }}>
                                            <img src={prod.featuredImage} style={{ width: "100%", height: 250 }}
                                                className="card-img-top" alt="..." />

                                            <div className="card-body">
                                                <p style={{ fontSize: 14 }} className="mb-4 fw-bold">{prod.productTitle}</p>
                                                <p className="card-title">{prod.description.substring(0, 27)}...</p>
                                                <div className="d-flex">
                                                    <del className="text-secondary actual-price">{prod && prod.price}$</del>
                                                    <p className="ms-3 discounted-price">{prod && prod.discountedPrice}$</p>
                                                </div>
                                                {isProductInCart(prod.slug) ? (
                                                    <Link className="btn btn-outline-dark cart-btn rounded-0 py-1 px-2 me-2" to='/cart' style={{ fontSize: "small" }}>
                                                        View Cart
                                                    </Link>
                                                ) : (
                                                    <button className="btn btn-outline-dark cart-btn rounded-0 py-1 px-2 me-2" style={{ fontSize: "small" }} onClick={() => addToCart(prod)}>
                                                        Add To Cart
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                ))

                            )
                        }
                    </div>
                    <div className="text-center mt-4">
                        <Link className="btn btn-dark cart-btn rounded-0 py-2 w-25" to='/shop'>
                            Shop More
                        </Link>
                    </div>

                    <div className="py-5">
                        <hr />
                    </div>
                </div>

                <div className="row g-0">
                    <div className="col-12 col-lg-6 d-none d-md-none d-lg-block d-xl-block">
                        <img src={secondSection1} alt="" />
                    </div>
                    <div className="col-12 col-lg-6 px-0 px-md-5 px-lg-0">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-12">
                                    <p className="fw-bold" style={{ fontSize: 12 }}>NEW COLLECTIONS</p>
                                    <p style={{ fontSize: 48 }}>Best Products From Known Brands for everyone!</p>
                                    <p>Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
                                        ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                    <button className="btn btn-dark rounded-0">Shop Now</button>
                                </div>
                                <div className="col-8 col-md-6 col-lg-12 m-auto">
                                    <div className="mt-5 float-end">
                                        <img src={secondSection2} style={{ width: "100%" }} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="third-section my-5 py-5" style={{ backgroundColor: "#edf2f9" }}>
                    <div className="text-center">
                        <img src="/assets/logo.webp" width="15%" alt="" />
                        <p className="col-6 m-auto py-4" style={{ fontSize: 24 }}>Eiusmod tempor incididunt ut labore et dolore
                            magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodobendo viverra maecenas accumsan
                            lacus vel facilisis libero.
                        </p>
                        <div className="col-2 m-auto mb-2 mt-2">
                            <img src={ceoSignature} width="100%" alt="" />
                        </div>
                        <p className="text-secondary fw-bold" style={{ fontSize: 12, letterSpacing: 2 }}>CHANEL CEO</p>
                    </div>
                </div>

                <div className="slider-container mb-5">
                    <div className="row align-items-center">
                        <div className="container rounded">
                            <div className="under-slider-container">
                                <div className="logos">
                                    <img className="brand-logos ms-4" src={brandLogo1} width="15%" alt="" />
                                    <img className="brand-logos mx-4" src={brandLogo2} width="15%" alt="" />
                                    <img className="brand-logos" src={brandLogo3} width="15%" alt="" />
                                    <img className="brand-logos mx-4" src={brandLogo4} width="15%" alt="" />
                                    <img className="brand-logos mx-4" src={brandLogo5} width="15%" alt="" />
                                    <img className="brand-logos mx-4" src={brandLogo6} width="15%" alt="" />
                                </div>
                                <div className="logos">
                                    <img className="brand-logos" src={brandLogo1} width="15%" style={{ marginLeft: 120 }} />
                                    <img className="brand-logos mx-4" src={brandLogo2} width="15%" alt="" />
                                    <img className="brand-logos" src={brandLogo3} width="15%" alt="" />
                                    <img className="brand-logos mx-4" src={brandLogo4} width="15%" alt="" />
                                    <img className="brand-logos mx-4" src={brandLogo5} width="15%" alt="" />
                                    <img className="brand-logos mx-4" src={brandLogo6} width="15%" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid" style={{ backgroundColor: "#edf2f9" }}>
                    <div id="carouselExampleControls" className="carousel slide py-5" data-bs-ride="carousel">
                        <div className="carousel-inner text-center py-3 px-5">
                            <div className="carousel-item px-5 active">
                                <h4>WHAT THEY'RE SAYING</h4>
                                <p className='mb-0 py-4 m-auto fs'>I recently purchased the latest camera featured on this site, and
                                    I'm blown away by its performance. The image quality is unparalleled, and the ease of use
                                    makes it
                                    <br />
                                    perfect for both beginners and seasoned photographers. Thanks to the detailed reviews, I
                                    made an informed decision. Highly recommended!
                                </p>
                                <h6 className='mt-4'>Alex Thompson</h6>
                                <p className='fs'>Hobbyist Photographer</p>
                            </div>
                            <div className="carousel-item px-5">
                                <h4>WHAT THEY'RE SAYING</h4>
                                <p className='mb-0 py-4 fs'>As a professional photographer, I rely on accurate and insightful
                                    reviews to choose my gear. The reviews on this site provided valuable information about the
                                    camera I was
                                    <br />
                                    eyeing. The detailed analysis and real-world usage experiences helped me make a confident
                                    purchase. Kudos to the team for their excellent work!
                                </p>
                                <h6 className='mt-4'>Sarah Rodriguez</h6>
                                <p className='fs'>Professional Photographer</p>
                            </div>
                            <div className="carousel-item px-5">
                                <h4>WHAT THEY'RE SAYING</h4>
                                <p className='mb-0 py-4 fs'>
                                    I stumbled upon this site while researching cameras, and I'm so glad I did. The reviews are
                                    written in a way that's easy to understand, even for someone new to photography. <br /> The
                                    recommended
                                    products are top-notch, and the site has become my go-to resource for camera information.
                                    Keep
                                    up the fantastic work!</p>
                                <h6 className='mt-4'>James Harper</h6>
                                <p className='fs'>Enthusiastic Beginner</p>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                            data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true">
                                <img src={previous} width="50px" alt="" />
                            </span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                            data-bs-slide="next">
                            <span>
                                <img src={next} width="50px" alt="" />
                            </span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </main>
        </>
    )
}
