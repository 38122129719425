import React, { useState } from 'react'
import { doc, setDoc, collection } from "firebase/firestore/lite";
import { dataBase } from '../../config/firebase'


export default function AddProduct() {
    const [productData, setProductData] = useState({ featuredImage: '', productTitle: '', slug: '', price: 0, discountedPrice: 0, description: '', category: '' });

    const handleChange = (e) => {
        setProductData({ ...productData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        const randomId = Math.random().toString(36).substr(2, Math.floor(Math.random() * 2) + 8);
        e.preventDefault();

        const productDocRef = doc(dataBase, "products", randomId);

        await setDoc(productDocRef, { ...productData });

        alert('Submitted Successfully!');
    };

    return (
        <div className='container' style={{ marginTop: 100 }}>

            <form className='form-control p-5' onSubmit={handleSubmit}>

                <h1>Add a New Product</h1>
                <div className="row mt-4">
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <label htmlFor="featuredImage">Featured Image Link:</label>
                        <input className='form-control' type="text" id="featuredImage" name="featuredImage" value={productData.featuredImage} onChange={handleChange} required />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <label htmlFor="productTitle">Title:</label>
                        <input className='form-control' type="text" id="productTitle" name="productTitle" value={productData.productTitle} onChange={handleChange} required />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <label htmlFor="slug">Slug:</label>
                        <input className='form-control' type="text" id="slug" name="slug" value={productData.slug} onChange={handleChange} required />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <label htmlFor="price">Price:</label>
                        <input className='form-control' type="text" id="price" name="price" value={productData.price} onChange={handleChange} required />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <label htmlFor="discountedPrice">Discounted Price:</label>
                        <input className='form-control' type="text" id="discountedPrice" name="discountedPrice" value={productData.discountedPrice} onChange={handleChange} required />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <label htmlFor="description">Description:</label>
                        <input className='form-control' type="text" id="description" name="description" value={productData.description} onChange={handleChange} required />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4 mb-3">
                        <label htmlFor="description">Category:</label>
                        <select
                            className="form-control"
                            id="category"
                            name="category"
                            value={productData.category}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a Category</option>
                            <option value="beauty-and-personal-care">Beauty & Personal Care</option>
                            <option value="home-and-kitchen">Home & Kitchen</option>
                            <option value="sports-and-outdoor">Sports & Outdoor</option>
                            <option value="office-products">Office Products</option>
                        </select>
                    </div>
                </div>

                <button type='submit' className='btn btn-outline-dark rounded-0 py-2 px-4'>Submit</button>
            </form>
        </div>
    )
}
