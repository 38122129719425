import React, { useEffect, useState } from 'react';
import mainCartImg from '../assets/main-cart-1.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export default function Cart() {
    const [cartProducts, setCartProducts] = useState([]);

    useEffect(() => {
        // Retrieve cart items from localStorage
        const cartFromStorage = JSON.parse(localStorage.getItem('cart')) || [];
        setCartProducts(cartFromStorage);
    }, []);

    const calculateSubtotal = () => {
        // Sum of prices for all items in the cart
        return cartProducts.reduce((total, product) => {
            const priceToAdd = product.discountedPrice ? product.discountedPrice : product.price;
            return total + Number(priceToAdd);
        }, 0);
    };

    const calculateTotal = () => {
        // You can modify this function based on your tax or additional fee logic
        return calculateSubtotal();
    };

    const removeFromCart = (productIndex) => {
        const updatedCart = [...cartProducts];
        updatedCart.splice(productIndex, 1);
        setCartProducts(updatedCart);
        localStorage.setItem('cart', JSON.stringify(updatedCart));
    };

    return (
        <>
            <div>
                <img src={mainCartImg} width="100%" alt="" />
            </div>
            <div className="container cart-container py-5">
                <div className="row">
                    <div id="cartProducts" className="col-lg-8">
                        {cartProducts.length ?

                            cartProducts.map((product, index) => (
                                <>
                                    <div key={index} className="row">
                                        <div className="col-2 m-auto">
                                            <img src={product.featuredImage} style={{ width: 100, height: 100, borderRadius: '50%' }} alt="" />
                                        </div>
                                        <div className="col-5 m-auto">
                                            <p className='mb-0' style={{ fontSize: 22 }}>{product.productTitle}</p>
                                        </div>
                                        <div className="col-1 m-auto">
                                            <del className='mb-0' style={{ fontSize: 22 }}>{product.price}$</del>
                                        </div>
                                        <div className="col-1 m-auto">
                                            <p className='mb-0' style={{ fontSize: 22 }}>{product.discountedPrice}$</p>
                                        </div>
                                        <div className="col-1 m-auto" style={{ cursor: 'pointer' }}>
                                            <FontAwesomeIcon className='text-dark' style={{ fontSize: 18 }} icon={faTrash} onClick={() => removeFromCart(index)} />
                                        </div>
                                    </div>
                                    <hr key={`hr-${index}`} />
                                </>
                            ))

                            : <>
                                <div className='container bg-light p-5'>
                                    <h1>No Products In Cart!</h1>
                                    <Link className="btn btn-outline-dark rounded-0 py-1 px-4 mt-2 rounded-4" to='/shop'>
                                        Shop Now
                                    </Link>
                                </div>
                            </>
                        }
                    </div>
                    <div className="col-lg-4">
                        <div className="total-card border-0 p-4" style={{ backgroundColor: "#f3f7fc" }}>
                            <h4 className="mb-4">Cart Total</h4>
                            <hr />
                            <p id="subtotal">Subtotal: ${calculateSubtotal()}</p>
                            <p>Tax: $0.00</p>
                            <hr />
                            <h5 id="total">Total: ${calculateTotal()}</h5>
                            <div className="text-end">
                                <button className="btn btn-dark rounded-0 px-4 py-2">Buy Now</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div >
        </>
    )
}
