import React from 'react'
import logo from '../../assets/logo-no-bg.png'
import { Link } from 'react-router-dom'

export default function index() {
    return (
        <>
            <header>
                <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
                    <div className="container-md">
                        <div className="medium-screen-links d-flex d-none">
                            <i className="color-primary me-3 fa-brands fa-facebook-f"></i>
                            <i className="color-primary me-3 fa-brands fa-instagram"></i>
                            <i className="color-primary fa-brands fa-linkedin"></i>
                        </div>
                        <Link className="navbar-brand" to="/">
                            <img className="ms-auto" src={logo} width="120px" alt="" />
                        </Link>
                        <button className="navbar-toggler border-0" aria-controls="navbarSupportedContent" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav m-auto mb-2 mb-lg-0 align-items-center">
                                <li className="nav-item">
                                    <Link to='/' className="nav-link fw-bold fs active" aria-current="page">
                                        Home
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to='/shop' className="nav-link fw-bold fs" aria-current="page">
                                        Shop
                                    </Link>
                                </li>
                                <li className="nav-item me-2">
                                    <Link to='/contact' className="nav-link fw-bold fs" aria-current="page">
                                        Contact
                                    </Link>
                                </li>
                                <li className="nav-item me-2">
                                    <Link to='/cart' className="nav-link d-flex align-items-center position-relative"
                                        id="cartLink">
                                        <p type="button" className="mb-0 me-2 d-none cart-txt">Cart</p>
                                        <i className="fa-solid fa-cart-shopping"></i>
                                        {/* <span
                                            className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-info"
                                            id="cartCount">
                                            {cart.length}
                                            <span className="visually-hidden">unread messages</span>
                                        </span> */}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex links">
                            <i className="color-primary me-3 fa-brands fa-facebook-f"></i>
                            <i className="color-primary me-3 fa-brands fa-instagram"></i>
                            <i className="color-primary fa-brands fa-linkedin"></i>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
