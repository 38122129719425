import React from 'react'
import mainContactImg from '../assets/Contact-bg.webp';
import secondContactImg from '../assets/contact-form2.jpg';

export default function ContactUs() {
    return (
        <>
            <div className="sections-banner-images w-100 mw-100 position-relative overflow-hidden" style={{ height: '70vh' }}>
                <div className="main-contact-img position-relative w-100">
                    <img src={mainContactImg} width="100%" height="100%" className="object-fit-cover" alt="" />
                </div>
            </div>

            <div className="container py-5">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="border-bottom" style={{ width: 100 }}></div>
                    <i>
                        <p className="mb-1 mx-3" style={{ fontSize: '1.8rem', fontWeight: 500 }}>Connect With Us</p>
                    </i>
                    <div className="border-bottom" style={{ width: 100 }}></div>
                </div>
                <div className="card mb-3 mt-4">
                    <div className="row g-0">
                        <div className="col-12 col-md-6 col-lg-6 m-auto contact-form-img position-relative">
                            <img src={secondContactImg} width="100%" height="100%" alt="" />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 px-5 m-auto">
                            <div className="row">
                                <div className="col-12 col-md-6 col-lg-6 mb-2 mb-lg-4">
                                    <label for="firstname" className="d-block mb-2 fw-bold text-secondary fs">First Name</label>
                                    <input type="text" name='firstname'
                                        className="border form-control border-secondary p-2 w-100 border-0 border-bottom rounded-0" />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-2 mb-lg-4">
                                    <label for="lastname" className="d-block mb-2 fw-bold text-secondary fs">Last Name</label>
                                    <input type="text" name='lastname'
                                        className="border form-control border-secondary p-2 w-100 border-0 border-bottom rounded-0" />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-2 mb-lg-0">
                                    <label for="email" className="d-block mb-2 fw-bold text-secondary fs">Email</label>
                                    <input type="text" name='email'
                                        className="border form-control border-secondary p-2 w-100 border-0 border-bottom rounded-0" />
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 mb-2 mb-lg-0">
                                    <label for="subject" className="d-block mb-2 fw-bold text-secondary fs">Subject</label>
                                    <input type="text" name='subject'
                                        className="border form-control border-secondary p-2 w-100 border-0 border-bottom rounded-0" />
                                </div>
                                <div className="col-12 mt-lg-4">
                                    <label for="message" className="d-block mb-2 fw-bold text-secondary fs">Message</label>
                                    <textarea name="message"
                                        className="border form-control border-secondary p-2 w-100 border-0 border-bottom rounded-0"
                                        id="" cols="20" rows="4"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid py-4" style={{ backgroundColor: '#edf2f9' }}>
                <div className="container px-5 text-center">
                    <p style={{ fontWeight: 500, fontSize: '1.8rem' }}>Signup for our weekly newsletter</p>
                    <div className="col-7 m-auto">
                        <form method="POST" action="/newsletter" className="p-3 mt-4" style={{ backgroundColor: '#edf2f9' }}>
                            <div className="d-flex align-items-center mt-4 border rounded-3">
                                <input id="email" type="email" name="email"
                                    className="form-control border-0 rounded-0 rounded-start py-2" />
                                <button type="submit"
                                    className="btn btn-dark px-4 py-2 border-0 rounded-0 rounded-end">Subscribe</button>
                            </div>
                        </form>

                    </div>
                </div>
                <div className="container mt-5">
                    <div className="row text-center">
                        <div className="col-12 col-sm-6 col-lg-4 py-3 mb-4 mb-sm-3 mb-md-3 mb-lg-0">
                            <i className="fa-solid fa-location-dot text-dark" style={{ fontSize: '2rem' }}></i>
                            <p className="fs mt-2 mb-0">3919 New Cut Rd, Mary land, United State.</p>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 py-3 mb-4 mb-sm-3 mb-md-3 mb-lg-0">
                            <i className="fa-solid fa-phone-volume text-dark" style={{ fontSize: '2rem' }}></i>
                            <p className="fs mt-2 mb-0">(12)-100-100-100.</p>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 py-3 mb-4 mb-sm-3 mb-md-3 mb-lg-0">
                            <i className="fa-solid fa-envelope text-dark" style={{ fontSize: '2rem' }}></i>
                            <p className="fs mt-2 mb-0"><a className='text-decoration-none outline-0 text-dark' href="mailto:contact@hucksterz.com">Contact@Hucksterz.com</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
