import React from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Cart from './pages/Cart'
import Shop from './pages/Shop'
import AddProduct from './components/Product/AddProduct'
import ContactUs from './pages/ContactUs'
import BeautyAndPersonalCare from './pages/categories/BeautyAndPersonalCare'
import HomeAndKitchen from './pages/categories/HomeAndKitchen'
import SportsAndOutdoor from './pages/categories/SportsAndOutdoor'
import OfficeProducts from './pages/categories/OfficeProducts'

export default function theRoutes() {
    return (
        <>
            <Header />

            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/cart' element={<Cart />} />
                <Route path='/shop' element={<Shop />} />
                <Route path='/contact' element={<ContactUs />} />
                <Route path='/beauty-and-personal-care' element={<BeautyAndPersonalCare />} />
                <Route path='/home-and-kitchen' element={<HomeAndKitchen />} />
                <Route path='/sports-and-outdoor' element={<SportsAndOutdoor />} />
                <Route path='/office-products' element={<OfficeProducts />} />
                <Route path='/addProduct' element={<AddProduct />} />
            </Routes>

            <Footer />
        </>
    )
}
