import React from 'react'
import logo from '../../assets/logo-no-bg.png'


export default function index() {
    return (
        <>
            <footer className="bg-light text-dark py-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-3 mt-lg-0 mt-xl-0">
                            <div className="footer-img">
                                <img width="75%" src={logo} alt="" />
                            </div>
                            <p className="mt-4">Helping businesses dominate the digital landscape with customized marketing
                                solutions and unmatched expertise.</p>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mt-4 mt-md-2 mt-lg-0 mt-xl-4">
                            <h2 className='mb-4'>Contact Us</h2>
                            <p>205, Al Ansari building, Al Muraqqabat, Deira, Dubai</p>
                            <button className="border-0 bg-transparent text-white fw-bold info-mail"><a className="text-white"
                                href="mailto:notsubhanahmad@gmail.com">Send Us Email...</a></button>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mt-4 mt-md-2 mt-lg-0 mt-xl-4">
                            <h2 className='mb-4'>What We Do</h2>
                            <p className="mb-2">Social Media Management</p>
                            <p className="mb-2">Website Design</p>
                            <p className="mb-2">Social Media Design</p>
                            <p className="mb-2">Digital Advertising</p>
                            <p className="mb-2">Social Ads Planner</p>
                        </div>

                        <div className="col-12 col-md-6 col-lg-3 mt-4 mt-md-2 mt-lg-0 mt-xl-4">
                            <h2 className='mb-4'>Information</h2>
                            <p className="mb-2">Privacy Policy</p>
                            <p className="mb-2">Terms & Conditions</p>
                            <p className="mb-2">Disclaimer</p>
                            <p className="mb-2">Support</p>
                            <p className="mb-2">FAQ</p>
                            <p className="mb-2">Account</p>
                            <p className="mb-2">Return Policy</p>
                        </div>

                    </div>

                </div>

                <div className="divider border-bottom mt-5 w-100"></div>

                <div className="container">
                    <div className="row mt-4">
                        <div className="col-12 text-center">
                            <p className="mb-0">&copy;2023 | Demo Digital Marketing Agency | All Rights Reserved</p>
                        </div>
                    </div>
                </div>

            </footer>
        </>
    )
}
