import './App.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import Routes from './routes';

function App() {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
